<script setup>
const modalVisible = ref(false)

function showVerifyTfaOtpModal() {
    modalVisible.value = true;
}

const emit = defineEmits(["refresh"]);

const countdownTime = 20; // Countdown time in seconds
const timer = ref(countdownTime);
const isDisabled = ref(false);
const buttonText = ref(`Send again`);
const otpCode = ref('');
const verifying = ref(false);
const store = useDefaultStore();

const startCountdown = () => {
    isDisabled.value = true;
    buttonText.value = `Send again (Wait ${timer.value} seconds)`;

    const interval = setInterval(() => {
        timer.value--;

        if (timer.value >= 0) {
            buttonText.value = `Send again (Wait ${timer.value} seconds)`;
        }

        if (timer.value < 0) {
            clearInterval(interval);
            buttonText.value = 'Send again';
            isDisabled.value = false;
            timer.value = countdownTime;
        }
    }, 1000);
};

function sendAgain() {

    if (isDisabled.value) return;

    // @todo
    // apiService.sendTransactionCodeAgain({
    //     withdrawalId: transactionId.value
    // }).then(() => {
    //     successMessage('Verification code sent successfully');
    //     startCountdown();
    // }).catch(e => {
    //     errorMessage(e);
    // }).finally(() => {
    //     timer.value = countdownTime;
    // })

}

function verify() {
    if(!otpCode.value) {
        return;
    }

    verifying.value = true;

    apiService.verifyTwoFactor(otpCode.value).then(() => {
        successMessage('Two factor successfully updated');
        store.loadUser();
        modalVisible.value = false;
        otpCode.value = '';
    }).catch(e => {
        errorMessage(e);
    }).finally(() => {
        verifying.value = false;
    });
}

function close() {
    modalVisible.value = false;
    otpCode.value = '';
}

onMounted(() => {
    globalOn('user::verifyTfaOtp', showVerifyTfaOtpModal);
})

onBeforeUnmount(() => {
    globalOff('user::verifyTfaOtp', showVerifyTfaOtpModal);
})

</script>

<template>
    <Modal v-if="modalVisible" @close="close" key="VERIFYTFAOTPMODAL">

        <div class="mx-auto py-14" style="max-width: 600px;">
            <div class="text-center">
                <div class="text-black/50 text-lg">Confirm operation</div>
                <div class="mt-6 text-lg">
                    <div class="my-6">We've sent you an email which contains a one-time verification code to authorize this operation.</div>
                </div>
            </div>

            <form @submit.prevent="verify" class="mt-10">
                <div class="mb-6">
                    <div class="text-black/50 mb-4">One-time verification code</div>
                    <input v-model="otpCode" type="text" placeholder="One time code" class="form-input">
                </div>

                <div class="text-center mt-10 flex gap-4 justify-center">
<!--                    <button type="button" class="btn bg-gray-300 hover:bg-black hover:text-white" :disabled="isDisabled" @click="sendAgain">-->
<!--                        {{ buttonText }}-->
<!--                    </button>-->
                    <button class="cursor-pointer btn bg-purple-light hover:bg-black hover:text-white" :disabled="!otpCode || verifying">
                        <LoadingIcon v-if="verifying" class="icon"/>
                        Verify
                    </button>
                </div>
            </form>
        </div>
    </Modal>
</template>