<script setup>
const feedbackModalVisible = ref(false);
const feedbackInitialParams = ref({});

const route = useRoute();

watch(() => route.fullPath, () => {
    close()
});

function openModal(params = {}) {
    trackEvent("tweet_editor_modal_active");
    feedbackInitialParams.value = params;
    feedbackModalVisible.value = true;
}

function close() {
    feedbackModalVisible.value = false;
    feedbackInitialParams.value = {};
}

onMounted(() => {
    globalOn('app::openTweetEditor', openModal);
})

onBeforeUnmount(() => {
    globalOff('app::openTweetEditor', openModal);
})
</script>

<template>
    <LazyModalsNewPostModal v-if="feedbackModalVisible" :initial-value="feedbackInitialParams?.initialValue" :layout="feedbackInitialParams?.layout" @close="close" @tweetPosted="close"/>
</template>