<script setup>

import NotificationIcon from "~/components/Icons/NotificationIcon.vue";
import SmileIcon from "~/components/Icons/SmileIcon.vue";

const store = useDefaultStore()
const notifyViaEmail = ref(store.user.allowEmailNotifications ?? false);
const showMore = ref(false);

const notificationCount = computed(() => {
    return store.notificationCount;
})

const notifications = computed(() => {
    return store.computedNotifications;
})

const user = computed(() => {
    return store.user;
})

function toggleNotificationsViaEmail() {

    if (!user.value?.verifiedEmail) {
        return;
    }

    notifyViaEmail.value = !notifyViaEmail.value;

    apiService.saveBasicSettings({
        allowEmailNotifications: notifyViaEmail.value
    }).then(() => {
        successMessage('Notifications updated.');
        store.loadUser();
    }).catch(e => {
        errorMessage(e)
    });
}

function markAllAsRead() {
    apiService.markAllAsSeen().then(() => {
        useDefaultStore().refreshNotifications();
    });
}

onMounted(() => {
    store.refreshNotifications();
})

</script>

<template>
    <div class="flex items-center justify-between font-semibold mb-2 pr-12 lg:pr-0">
        <div class="flex items-center gap-3 text-lg">
            <NotificationIcon/>
            <div>Notifications</div>
            <span v-if="notificationCount" class="text-xs bg-red text-white rounded-2xl py-0.5 px-2.5 relative">
                <span class="relative" style="top: 0.5px">
                {{ notificationCount }}
                </span>
            </span>
        </div>
        <button class="cursor-pointer" :class="[notificationCount ? 'hover:underline text-purple' : 'text-grey-light']" :disabled="!notificationCount" @click="markAllAsRead">
            <span class="block md:hidden">
                <IconsEnvelopeIcon/>
            </span>
            <span class="hidden md:block">
            Mark all as Read
            </span>
        </button>
    </div>

    <div v-for="item in notifications" class="font-semibold py-6 border-b border-black/10">
        <NotificationSimple :item="item"/>
    </div>

    <!--    <div class=" font-semibold py-6 border-b border-black/10">-->
    <!--        <div class="flex items-start justify-between mb-2 gap-20">-->
    <!--            <div>-->
    <!--                <span class="bg-red inline-block w-2 h-2 mr-2 rounded-full"></span>-->
    <!--                Evaluation of campaign from Marinade is finished!-->
    <!--                <div class="relative w-9/12 mb-3 mt-3 bg-black/10 rounded">-->
    <!--                    <span class="block h-6 bg-purple-light rounded" style="width: 20%;"></span>-->
    <!--                    <span class="absolute top-0.5 left-2 text-xs">Campaign ends in 4 days</span>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            <div class="w-8 h-8 p-2 flex items-center justify-center bg-purple-light rounded-full">-->
    <!--                <WalletIcon/>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <div class="flex items-center justify-between">-->
    <!--            <a href="#" class="text-purple hover:underline">Pick up rewards</a>-->
    <!--            <div class="text-black/50">3 days ago</div>-->
    <!--        </div>-->
    <!--    </div>-->

    <!--    <div class=" font-semibold py-6 border-b border-black/10 mb-6">-->
    <!--        <div class="flex items-start justify-between mb-2 gap-20">-->
    <!--            <div class="text-black/50">Evaluation of campaign from Marinade is finished! Don’t forget to pickup the rewards for your work.</div>-->
    <!--            <div class="w-8 h-8 p-2 flex items-center justify-center bg-black fill-white rounded-full">-->
    <!--                <TweetIcon/>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <div class="flex items-center justify-between">-->
    <!--            <a href="#" class="text-purple hover:underline flex items-center gap-1">Visit our blog<NewTabIcon/></a>-->
    <!--            <div class="text-black/50">3 days ago</div>-->
    <!--        </div>-->
    <!--    </div>-->

    <div class="flex items-center justify-between font-semibold  mt-6">
        <button class="opacity-20 cursor-not-allowed" :class="{'hover:underline': showMore}" :disabled="!showMore">Show older</button>
        <span></span>
        <div class="flex items-center gap-5">
            <div @click="toggleNotificationsViaEmail" :class="{
                        'bg-purple-light': notifyViaEmail,
                        'bg-black/15': !notifyViaEmail,
                    }" class="relative inline-flex items-center h-6 rounded-full w-11 cursor-pointer transition-colors duration-300">
                    <span :class="{
                        'translate-x-6 fill-black': notifyViaEmail,
                        'translate-x-1 fill-white': !notifyViaEmail,
                        }" class="inline-flex items-center justify-center w-4 h-4 bg-white transform rounded-full transition-transform duration-300"><SmileIcon/></span>
            </div>
            Notify me via Email
        </div>
    </div>
</template>