<script setup>
import NewsSidebar from "~/components/Sidebars/NewsSidebar.vue";
import Paychecks from "~/components/Paycheck/Paychecks.vue";
import { setTokens } from "~/utils/authHelpers.js";
import { initializeAmplitude } from "~/utils/analytics.js";

const defaultStore = useDefaultStore();
const loading = ref(true);
const route = useRoute();

const user = computed(() => {
  return defaultStore.user;
});

const notificationSidebarOpen = computed(() => {
  return defaultStore.notificationSidebarOpen;
});

watch(
  () => route.fullPath,
  () => {
    defaultStore.closeNotificationSidebar();
    defaultStore.refreshNotifications();
  },
);

watch(
  user,
  (newUser, oldUser) => {
    if (!oldUser?.userId && newUser?.userId) {
      initData();
    }
  },
  {
    immediate: true,
  },
);

function initData() {
  defaultStore.refreshNotifications();
  defaultStore.loadPaychecks();
}

onMounted(() => {
  initializeAmplitude();

  const accessToken = route.query?.access_token ?? "";
  const refreshToken = route.query?.refresh_token ?? "";

  useRouter().replace({ query: {} });

  if (accessToken && refreshToken) {
    setTokens(accessToken, refreshToken);
    // clearTokens();

    defaultStore.loadUser().finally(() => {
      loading.value = false;
    });
  } else {
    loading.value = false;
  }
});
</script>

<template>
  <PageLoader v-if="loading" />
  <EvangelistLanding v-else-if="!user.userId" />
  <div v-else class="text-sm font-semibold">
    <div style="min-height: 100vh">
      <AppHeader />
      <div class="mt-2 lg:mt-4">
        <NuxtPage />
      </div>
      <Sidebar
        v-if="notificationSidebarOpen"
        @close="defaultStore.closeNotificationSidebar"
      >
        <NewsSidebar />
      </Sidebar>
    </div>
    <Paychecks />
    <GlobalTweetDetailSidebar />
    <GlobalFtuModal />
    <GlobalWithdrawalOtpModal />
    <GlobalVerifyEmailOtpModal />
    <GlobalVerifyTfaOtpModal />
    <GlobalNewPostModal />
  </div>
</template>

