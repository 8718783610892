<script setup>
const tweetId = ref(null);

function openModal(response) {
    tweetId.value = response.tweetId;
}

onMounted(() => {
    globalOn("tweetPosted", openModal);
});

onBeforeUnmount(() => {
    globalOff("tweetPosted", openModal);
});

</script>

<template>
    <Sidebar v-if="tweetId" @close="tweetId = null">
        <SidebarsTweetDetailSidebar :tweet-id="tweetId"/>
    </Sidebar>
</template>