export function addMemberStyles(members = [], layout = "default") {

    return members.map((member, index) => {

        let rowClass = '';

        if (member?.isLoggedUser) {
            rowClass = 'bg-black text-white';


        } else if (index == 0) {
            rowClass = 'bg-purple-light';

            if (layout.value == "paycheck") {
                rowClass = 'bg-white text-black';
            }

        } else if (index == 1) {
            rowClass = 'bg-grey-light';

            if (layout.value == "paycheck") {
                rowClass = 'bg-black/20 text-black';
            }

        }

        return {
            ...member,
            rowClass,
        }
    });


}