<script setup>
import {NotificationType} from "~/common/Enums.ts";
import NewTabIcon from "~/components/Icons/NewTabIcon.vue";

const props = defineProps({
    item: {
        type: Object,
        default: () => ({})
    }
})

const item = computed(() => props.item)
const store = useDefaultStore()

function handleCampaignDetailAction(item) {
    let campaignId = item?.callToActionParam?.campaignId ?? null;
    if (campaignId) {
        useRouter().push("/campaign/" + campaignId)
    } else {
        useRouter().push("/campaigns")
    }
}

const itemTypeConfig = [
    {
        // ok
        notificationType: NotificationType.CAMPAIGN_JOINED,
        ctaLabel: "Visit Campaign",
        alwaysShowCta: true,
    },
    {
        // ok
        notificationType: NotificationType.CAMPAIGN_ENDED,
        ctaLabel: "Visit Campaign",
        alwaysShowCta: true,
    },
    {
        // ok
        notificationType: NotificationType.CAMPAIGN_MATURITY,
        ctaLabel: "Claim Rewards",
        alwaysShowCta: true,
    },
    {
        // ok
        notificationType: NotificationType.NEW_ELIGIBLE_POSTS,
        ctaLabel: "Claim Rewards",
        alwaysShowCta: true,
    },
    {
        // ok
        notificationType: NotificationType.TRANSACTION_VERIFYING,
        ctaLabel: "Verify Transaction",
        alwaysShowCta: false,
    },
    {
        // ok
        notificationType: NotificationType.TRANSACTION_COMPLETED,
        ctaLabel: "See on Blockchain",
        alwaysShowCta: true,
    },
    {
        // ok
        notificationType: NotificationType.TERMS_CONDITIONS,
        alwaysShowCta: false,
    },
];

const currentEventType = computed(() => {
    return itemTypeConfig.find(itemType => {
        return itemType.notificationType === item.value?.eventType
    }) ?? null;
})

const computedCtaLabel = computed(() => {
    return currentEventType.value?.ctaLabel ?? "Mark as seen";
});

const computedShowCtaLabel = computed(() => {
    if (currentEventType.value?.alwaysShowCta) {
        return true;
    }

    return item.value?.isRead ? false : true;
});

function apiMarkAsSeen() {
    apiService.markAsSeen(item.value.id).then(() => {
        useDefaultStore().refreshNotifications();
    });
}

function refreshNotifications() {
    store.refreshNotifications();
}

function closeNotifications() {
    store.closeNotificationSidebar();
}

async function handleCtaClick(item) {

    const eventType = item.eventType;

    if (eventType === NotificationType.CAMPAIGN_JOINED || eventType === NotificationType.CAMPAIGN_ENDED) {
        handleCampaignDetailAction(item);

        apiMarkAsSeen();

        refreshNotifications();
        closeNotifications();

        return;
    }

    if (eventType === NotificationType.CAMPAIGN_MATURITY) {

        store.paychecks = [];
        store.loadPaychecks();

        apiMarkAsSeen();
        refreshNotifications();
        closeNotifications();
        return;
    }

    if (eventType === NotificationType.NEW_ELIGIBLE_POSTS) {
        await useRouter().push("/");
        apiMarkAsSeen();
        refreshNotifications();
        closeNotifications();
        return;
    }

    if (eventType === NotificationType.TRANSACTION_VERIFYING) {
        globalEmit("transaction::showOtpModal", item?.callToActionParam?.withdrawalId);
        apiMarkAsSeen();
        refreshNotifications();
        return;
    }

    if (eventType === NotificationType.TRANSACTION_COMPLETED) {
        apiMarkAsSeen();
        refreshNotifications();
        closeNotifications();
        return;
    }

    if (eventType === NotificationType.TERMS_CONDITIONS) {
        apiMarkAsSeen();
        refreshNotifications();
        return;
    }

}

</script>

<template>
    <div>
        <div class="flex lg:items-center justify-between mb-2 gap-10 lg:gap-20">
            <div class="" :class="{'text-black/50': item.isRead}" style="max-width: 350px">
                <span v-if="!item.isRead" class="bg-red inline-block w-2 h-2 mr-2 rounded-full shrink-0"></span>
                <span v-if="item.eventType == NotificationType.TERMS_CONDITIONS">
                    Welcome to Evangelist. By signing up, you agreed to our <NuxtLink class="underline" to="/articles/terms" target="_blank">User Terms</NuxtLink> and <NuxtLink class="underline" to="/articles/privacy-policy" target="_blank">Privacy Policy</NuxtLink>.
                </span>
                <span v-else>
                {{ item.eventText }}
                </span>
                <div v-if="item.eventType == NotificationType.CAMPAIGN_JOINED" class="my-3">
                    <NotificationCampaignProgressBar :params="item.callToActionParam"/>
                </div>
            </div>
            <div class="w-8 h-8 shrink-0">
                <BrandsIcon v-if="item?.callToActionParam?.advertiserTheme" :theme="item.callToActionParam.advertiserTheme"/>
                <div v-else-if="[NotificationType.NEW_ELIGIBLE_POSTS].includes(item.eventType)" class="bg-black rounded-icon text-white">
                    <IconsTweetIcon/>
                </div>
                <div v-else-if="[NotificationType.TRANSACTION_VERIFYING, NotificationType.TRANSACTION_COMPLETED].includes(item.eventType)" class="bg-purple-light rounded-icon">
                    <IconsWalletIcon/>
                </div>
                <BrandsEvangelistIcon v-else class="w-full h-full"/>
            </div>
        </div>
        <div class="flex items-center justify-between mt-5 lg:mt-0">
            <a v-if="item.eventType == NotificationType.TRANSACTION_COMPLETED" :href="item?.callToActionParam?.externalTransactionUrl" target="_blank" class="flex items-center gap-1 text-purple font-semibold" @click="handleCtaClick(item)">
                See on Blockchain
                <NewTabIcon/>
            </a>
            <div v-else>
                <button v-if="computedShowCtaLabel" class="text-purple hover:underline cursor-pointer" @click="handleCtaClick(item)">{{ computedCtaLabel }}</button>
            </div>
            <div class="text-black/50">
                <TimeAgo :date="item.dateCreated"/>
            </div>
        </div>
    </div>
</template>