<script setup>

const INVITED = "invited";
const UNINVITED_ELIGIBLE = "UNINVITED_ELIGIBLE";
const UNINVITED_INELIGIBLE = "UNINVITED_INELIGIBLE";

const modalVisible = ref(false);
const modalType = ref(null);
const modalParams = ref({});

const store = useDefaultStore();
const ftuNotification = computed(() => store.ftuNotification);

watch(ftuNotification, (value) => {
    if (value?.id) {
        let {
            hasEligibleCampaign,
            hasEligiblePosts,
        } = value?.callToActionParam ?? {};

        let selectedModalType = null;

        if (hasEligibleCampaign && hasEligiblePosts) {
            selectedModalType = INVITED;
        } else if (hasEligibleCampaign && !hasEligiblePosts) {
            selectedModalType = UNINVITED_ELIGIBLE;
        } else {
            selectedModalType = UNINVITED_INELIGIBLE;
        }

        modalParams.value = value?.callToActionParam;
        modalType.value = selectedModalType;
        modalVisible.value = true;

        setNotificationAsSeen(value.id)
    }
}, {
    immediate: true
});

function setNotificationAsSeen(notificationId) {
    apiService.markAsSeen(notificationId).then(() => {
        store.refreshNotifications();
    });
}

// onMounted(() => {
//
//     setTimeout(() => {
//         modalVisible.value = true;
//         modalType.value = UNINVITED_ELIGIBLE;
//     }, 3000);
//
// });

</script>

<template>

    <Modal v-if="modalVisible" class="modal--content" @close="modalVisible = false">
        <LazyModalsWelcomeModal v-if="modalType == INVITED" :metadata="modalParams" @close="modalVisible = false"/>
        <LazyModalsWelcomeEligibleUserModal v-else-if="modalType == UNINVITED_ELIGIBLE" :metadata="modalParams" @close="modalVisible = false"/>
        <LazyModalsWelcomeStrangerModal v-else-if="modalType == UNINVITED_INELIGIBLE" :metadata="modalParams" @close="modalVisible = false"/>
    </Modal>

</template>