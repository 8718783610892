<script setup>
const props = defineProps({
    params: Object,
});

const params = computed(() => props.params);

const startDate = computed(() => {
    return new Date(params.value.campaignStartDate);
});

const endDate = computed(() => {
    return new Date(params.value.campaignEndDate);
});

const dates = computed(() => {
    return getCampaignDateStatus(startDate.value, endDate.value);
});

const progressBar = computed(() => {
    let isUrgent = false;

        return {
            label: "Campaign ends in " + dates.value.endsIn,
            width: progressBarWidthUntillEndDate.value,
            barClass: isUrgent ? 'bg-red-light' : 'bg-purple-light',
            labelClass: isUrgent ? 'text-red' : '',
            urgent: isUrgent
        }
})

const progressBarWidthUntillEndDate = computed(() => {
    const totalDuration = endDate.value - startDate.value;
    const timePassed = new Date() - startDate.value;
    return (timePassed / totalDuration) * 100 + '%';
});
</script>

<template>
    <div class="relative w-9/12 bg-black/10 rounded-lg">
        <span class="absolute block h-full rounded-lg" :class="progressBar?.barClass" :style="{width: progressBar.width}"></span>
        <div class="relative p-1.5 px-3 flex gap-1" :class="progressBar.labelClass">
            <IconsFireIcon v-if="progressBar.urgent"/>
            {{ progressBar.label }}
        </div>
    </div>
</template>