<script setup>
const props = defineProps({
    post: Object,
});

const post = computed(() => {
    return props.post;
});

</script>

<template>
    <div class="bg-gradient-to-b from-white to-gray-200 border border-gray-300 hover:bg-white hover:shadow-blur cursor-pointer rounded-2xl p-6 pt-5 font-semibold">
        <div class="flex flex-nowrap items-center justify-between mb-4 gap-3">
            <div class="text-gray-500 text-xs line-clamp-1">
                Post relevance
            </div>
            <div class="flex flex-row gap-2 text-xs">
                <div class="bg-black text-white flex-shrink-0 rounded-2xl px-2 leading-5 inline-flex items-center gap-2">{{transformToPercentage(
                    post.score)}}</div>
                <div class="bg-purple-light text-black flex-shrink-0 rounded-2xl px-2 leading-5 inline-flex items-center gap-2">
                    {{formatNumberUSA(post?.reward?.amount)}} {{post?.reward?.currency}}
                </div>
            </div>
        </div>
        <p class="mb-4 line-clamp-2" v-html="truncateString(post.content, 180)"></p>
        <div class="flex justify-between items-center">
            <div class=" text-black/30">
                <TimeAgo :date="post.datePosted"/> via {{post.origin == "x" ? "X.com" : "Evangelist"}}
            </div>
        </div>
    </div>
</template>